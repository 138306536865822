import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgParentNotListening from "../images/parent-not-listening.jpg"
import imgSchoolBusDisruption from "../images/article-keep-parents-schools-happy-during-school-bus-disruption.jpg"
import imgMobileTicket from "../images/mobile-ticket-for-school-transport.jpg"
import imgSaveTime from "../images/save-time-money-using-shuttleid.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact"
import Footer from "../components/footer"
import Nav from "../components/nav"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="How to keep parents and schools informed during school bus disruption" />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">How to keep parents and schools informed during school bus disruption</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">How to keep parents and schools informed during school bus disruption</h1>
              </div>

              <div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>School bus disruption is inevitable. Beyond beating all the odds of drivers consistently starting journeys on time and having no issues with any vehicles, operators are still at the mercy of traffic, roadworks and bad weather conditions. Eventually there will be a spanner in the works.</p>
                    <p>Parents and schools are understanding but what they find unforgivable is poor communication. So how as an operator can you keep parents and schools constantly informed with the latest information?</p>
                    <p><img src={imgSchoolBusDisruption} alt="" /></p>
                  </div>
                  <div className='article-inner'>
                    <h3>1. Service alerts</h3>
                    <h4>Parent alerts</h4>
                    <p>Keeping parents informed should be a key priority during service disruption. Parents want to know if disruption means they should keep their child at home longer before sending them to wait in the freezing cold at the bus stop. They need to know their child is safe at the bus stop and how much longer they'll need to be without supervision. They want to know if they need to arrange alternative travel plans and they need their expectations managed.</p>
                    <p>Without direct communication parents are left guessing, leading to parents riling each other up in WhatsApp groups and on social media. This can escalate and result in parents taking out their frustration with the operator over the phone or in emails, and more often than not in a very public way on social media and in online reviews.</p>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>How ShuttleID helps</span>
                    Our SMS service alert feature empowers operators to mass message parents. Valid ticket holders are automatically opted in which means you don't have to separately maintain a list of phone numbers yourself. Phone numbers are automatically managed by end users.<br/><br/>
                    SMS service alerts help because they are highly visible and they require no technical know-how from end users. This means your important message gets to the right people and right away.<br /><br />
                    As it's direct and private communication, it also keeps the dialogue away from public forums such as social media, further protecting your reputation.</p>

                    <h4>School alerts</h4>
                    <p>Schools equally need to be kept informed so they can understand the impact ahead of time and evaluate if there are additional safeguarding concerns they need to be aware of.</p>
                    <p>Keeping schools informed not only helps your relationship and reputation, but it can also help act as a further buffer from inbound parent communication, making this a no-brainer.</p>
                    <p>What makes this even more challenging however is that the times you need to alert school of disruption, the school phone lines are typically offline and unmanned.</p>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>How ShuttleID helps</span>
                    Beyond ticket holders, you can configure additional contacts to receive service alerts. A typical use case for this feature is to add a phone number for a contact at the school (e.g. business manager or estates manager), so they receive the service alert at the same time as all of your passengers.</p>

                    <h3>2. Vehicle tracking</h3>
                    <p>Being able to live track the vehicle helps parents and students understand if the school bus is on time or if it's still on the way and how long it's likely to take. Many parent phone calls and messages could easily be avoided if they simply knew exactly where the bus was.</p>
                    <p></p>
                    <p>Although traditional vehicle tracking devices fixed to vehicles can help with this, they aren't a perfect fit for this use case:</p>
                    <ul style={{ listStyleType: 'none' }}>
                      <li>❌ Requires all vehicles to have tracking fitted to support changing of vehicles</li>
                      <li>❌ Requires someone to constantly update secure user access so new joiners can track but school leavers can't</li>
                      <li>❌ Requires someone to constantly update tracking availability so prevent users tracking vehicles 24/7 whilst on other jobs</li>
                    </ul>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>How ShuttleID helps</span>
                    ShuttleID enables parents and students to live track their vehicle directly from their digital ticket.<br /><br />As the tracking is powered by a mobile device on board the vehicle, this makes it cost-effective, portable and secure. It only reveals location data whilst the journey is in transit.<br /><br />
                    With the Agent Portal, operators can additionally offer vehicle tracking access in a secure way to third parties such as schools or local authorities. In a similar vain, only the journey tracking is shared, and not 24/7 tracking, which helps protect commercially sensitive data.</p>

                    <h3>3. Boarding notifications</h3>
                    <p>Parent boarding notifications add an extra layer of assurance to parents that their child has safely boarded the bus. A critical feature for parents of vulnerable children and incredibly useful when there is service disruption, boarding notifications also help parents adjust their plans.</p>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>How ShuttleID helps</span>If a parent opts in to receive boarding notifications, when the passenger boards and scans their ticket, a notification is automatically sent to the parent. The notification includes the timestamp of boarding, which bus was boarded and the ability to live track the vehicle.<br/><br/>All the reassurance a parent needs to know that their child is safely en route.</p>

                    <h2>Summary</h2>
                    <p>Ensuring your school bus runs to exact schedule every single journey is what many operator strive to achieve, but realistically disruption will eventually happen and often it's outside of your control.</p>
                    <p>Introducing service alerts, vehicle tracking and parent boarding notifications on your school bus will boost the perceived quality of your school bus service and make disruption manageable. This helps not only protect your reputation and relationships but it also helps reduce admin and stress at the exact time it needs to be avoided.</p>
                    <p style={{ background: '#084e96', color: '#fff', padding: 15 }}><span style={{ display: 'block', fontWeight: 'bold' }}>Can ShuttleID help me?</span>Yes! The benefits of ShuttleID not only makes running your school bus easier to manage but importantly it makes your customers happy. Our technology is designed to be simple, affordable and keeps you in full control. Get in touch today on 0333 344 9868 for more information or book your <Link style={{ fontWeight: 'bold', color: 'white' }} to="/book-demo/">free online demo</Link>.</p>

                    <div className='author' style={{ border: '1px solid #d5e6f5', background: '#f3f9ff', marginBottom: '10px' }}>
                      <div className='author__img'>
                        <img src={imgProfileChrisBell} alt="Chris Bell" />
                      </div>
                      <div className='author__quote' style={{  }}>
                        <p style={{ marginBottom: 10 }}><strong>About the author</strong></p>
                        <p style={{ marginBottom: 10 }}>Chris Bell is a director at ShuttleID; the affordable, digital bus pass and ticketing system that keeps you in control.</p>
                        <p style={{ marginBottom: 10 }}>During a 15 year career in technology, Chris worked on many websites and systems for coach companies and saw the issues of managing home-to-school first hand, leading to the launch of ShuttleID in 2019.</p>
                        <p style={{ margin: 0 }}>Email: <a className="link" href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> | Phone: 0333 344 9868</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage